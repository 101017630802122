@import url("./_global.scss");

#root {
  height: 100vh;
  display: flex;
}

.ant-menu-sub {
  background-color: theme("colors.purple.200") !important; 
}

.ant-menu-item-active, .ant-menu-item-selected{
  background-color: theme("colors.purple.300") !important; 
}